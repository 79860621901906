import type { Shop, User } from "@/types";
import { motion } from "framer-motion";
import { Heart, Search, ShoppingCart } from "lucide-react";
import type { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  shop: Shop;
  user?: User | null;
}

const Header = ({ shop, user, children }: Props) => {
  return (
    <header className="bg-kp-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between py-4 gap-4 md:gap-8">
          <a
            href={`/shops/${shop?.slug}`}
            className="text-2xl font-bold shrink-0 text-center md:text-left flex items-center">
            <img
              src={shop.logo || ""}
              alt={shop.name}
              className="h-16 w-16 mr-2"
            />
            <div>
              <div className="text-kp-primary">{shop?.name}</div>
              <div className="text-sm font-normal text-kp-secondary">
                {shop?.slogan}
              </div>
            </div>
          </a>

          <div className="flex-1 w-full max-w-3xl">
            <div className="relative">
              <input
                type="search"
                placeholder="Search products in Kundarilla Palace"
                className="w-full px-4 py-2 pl-10 border border-kp-black/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-kp-primary transition-all"
              />
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-kp-secondary" />
            </div>
          </div>

          <div className="flex items-center gap-4">
            {/* cart button */}
            {children}

            <button
              className="relative p-2 hover:bg-kp-accent/10 rounded-full transition-colors"
              aria-label="Favorites">
              <Heart className="w-6 h-6 text-kp-primary" />
              <span className="absolute -top-1 -right-1 bg-kp-accent text-kp-black w-5 h-5 rounded-full text-xs flex items-center justify-center">
                0
              </span>
            </button>
            {user ? (
              <>
                <a href="/dashboard">
                  <motion.button
                    className="px-4 py-2 bg-kp-black text-kp-accent rounded text-sm font-medium transition-colors"
                    whileTap={{ scale: 0.95 }}>
                    My Account
                  </motion.button>
                </a>
              </>
            ) : (
              <>
                <a href="/login">
                  <button className="px-4 py-2 text-sm font-medium hover:bg-kp-accent/10 rounded transition-colors">
                    Login
                  </button>
                </a>
                <a href="/register">
                  <motion.button
                    className="px-4 py-2 bg-kp-black text-kp-accent rounded text-sm font-medium transition-colors"
                    whileTap={{ scale: 0.95 }}>
                    Sign Up
                  </motion.button>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
